// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


/** Ionic CSS Variables **/

// https://coolors.co
// https://forum.ionicframework.com/t/how-to-calculate-the-value-of-the-colors-shade-and-tint/162467/5

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function contrast($color) {
  // @if (red($color) * 0.299 + green($color) * 0.587 + blue($color) * 0.114) > 186 {
  @if (red($color) * 0.299 + green($color) * 0.587 + blue($color) * 0.114) > 250 {
    @return #222428;
  } @else {
    @return #ffffff;
  }
}

$theme-colors: (
  'primary': #BE1F3F,
  'secondary': #3dc2ff,
  'tertiary': #5260ff,
  'success': #2dd36f,
  'warning': #ffc409,
  'danger': #eb445a,
  'dark': #222428,
  'medium': #6174c2,
  'light': #ffffff,
  'darkgray': #244379,
	'lightgray': #eeeeee,
	'gray': #8f8f8f,

  'instagram': #e1306c,
  'facebook': #4267b2,
  'youtube': #ff0000,
);

:root {
  @each $name, $value in $theme-colors {
    --ion-color-#{$name}: #{$value};
    --ion-color-#{$name}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
    --ion-color-#{$name}-shade: #{shade($value, 12%)};
    --ion-color-#{$name}-tint: #{tint($value, 10%)};
    --ion-color-#{$name}-contrast: #{contrast($value)};
    --ion-color-#{$name}-contrast-rgb: #{red(contrast($value))},
      #{green(contrast($value))}, #{blue(contrast($value))};
  }
}

@each $name, $value in $theme-colors {
	.ion-color-#{$name} {
		--ion-color-base: var(--ion-color-#{$name});
		--ion-color-base-rgb: var(--ion-color-#{$name}-rgb);
		--ion-color-contrast: var(--ion-color-#{$name}-contrast);
		--ion-color-contrast-rgb: var(--ion-color-#{$name}-contrast-rgb);
		--ion-color-shade: var(--ion-color-#{$name}-shade);
		--ion-color-tint: var(--ion-color-#{$name}-tint);
	}
}


// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
  body.dark , ion-content.dark{
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  // .ios body {
  .ios body.dark , .ios ion-content.dark{
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  // .ios ion-modal {
  .ios .dark ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  // .md body {
  .md body.dark, .md.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
// }

:root{
  --font-size-base: 14px; //1rem;
  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgba: var(--ion-color-dark-rgba);
  // --font-size-lg: 1rem;
  // --font-size-sm: 1rem;
}

.sc-ion-alert-md-h{
  --min-width:40vh;
}
