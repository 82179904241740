@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/*Added For Me*/
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap';
@import '../node_modules/@swimlane/ngx-datatable/themes/dark';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons';
/*Added For Me*/

/* Lista de Posts */

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap{
  text-align: center;
  color: black;
}

.datatable-header {
  margin-top: 10px;
  font-size: 16px;
}


.colImg{
  padding-top: 10px;
}
.cols{
  text-align: center;
}
.cols p{
  margin: 0;
}
.list{
  grid-column-end: span 12;
}
.message{
  height: 80px;
  overflow: scroll;
  display: grid;
  align-items: center;
}
.imgList{
  width: 150px;
  object-fit: contain;
  height: 80px;
}
.iconPost{
  font-size: 30px;
}
.ngx-datatable .datatable-body .datatable-body-row>div{
  align-items: center;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a{
  text-decoration: none;
  padding: 9px;
    border: solid 1px #737373;
    margin: 3px;
    color: #737373;
}

.ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a{
  background-color: #737373;
  color: #fff;
}
/*Fim lista de Posts*/

// Campaign-list

.dates {
  font-weight: 300;
  border-style: line;
  border: 2px;
  border-color: aqua;
}
.cols .c{
  text-transform: capitalize;
  font-weight: 700;
}
// End of Campaign-list
.ion-margin-bottom {
  --ion-margin: 8px;
  // --margin-bottom: var(--ion-margin, 16px);
  // margin-bottom: var(--ion-margin, 16px);
}

// FONT SIZE
html{
  font-size: var(--font-size-base); // 16px
}
h1 {
  margin-top: 1.250rem;//20px;
  font-size: 1.625rem;// 26px;
}
h2 {
  margin-top: 1.125rem; //18px;
  font-size: 1.500rem; //24px;
}
h3 {
  font-size: 1.375rem; //22px;
}
h4 {
  font-size: 1.250rem; //20px;
}
h5 {
  font-size: 1.125rem; //18px;
}
h6 {
  font-size: 1.000rem; //16px;
}
small {
  font-size: 75%;
}
sub,
sup {
  font-size: 75%;
}

ion-item{
  --min-height: 3.000rem;
}
button.alert-tappable{
  height: 3.000rem;
}

ion-card-title,
ion-card-header{
  font-size: 1.250rem;
}
ion-card-header{
  padding: 0.250rem;
}

ion-label,
ion-input,
ion-select,
.alert-radio-label{
  font-size: 1.000rem !important;
}

.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

ion-content {
  --background: #fff !important; /* Define o fundo branco */
}

// ion-card-title {
//   color: var(--ion-color-gray) !important;
// }

ion-fab-button{
  &.fab-button-small{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

body,*{
  scrollbar-width: thin;
  scrollbar-color: var(--ion-color-primary) transparent;

  font-family: 'Poppins', sans-serif;
}
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-primary);
    border: 1px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
}

ion-fab,ion-fab-button{
  --box-shadow: none;
}

table{
  thead{
    border-bottom: 1px solid var(--ion-color-dark);
  }
  tbody tr{
    border-bottom: 1px solid var(--ion-color-gray);
  }
  tr{
    min-height: 2.750rem;
  }
  th{
    text-transform: uppercase;
  }
  td,th{
    // padding: 0.5rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .number,.right{
      text-align: right;
    }
    .center{
      text-align: center;
    }
  }
  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    left: 0px;
    z-index: 10;
    padding-left: 16px;
  }
}

table-wide,table-long{
  .native-input[disabled] {
    opacity: 1 !important;
  }
}

canvas{
  display: block;
}

.grid{

  --grid-gap: 1vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: var(--grid-gap);
  margin: var(--grid-gap) calc(var(--grid-gap) + 1px) var(--grid-gap) var(--grid-gap);// 10px 11px 10px 10px;
  // min-height: calc(100% - 2*var(--grid-gap));
  .grid{
    display: grid !important;
  }
}
.grid > * {
  display: block;
  width:100%;
  height:100%;
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  margin:0;
}

.pageTitles{
  color: #004050;
  padding-left: 10px;
}


// .js-plotly-plot .plotly{
//   height: 220px;
// }
// .icon-inner{
//   color:#fff;
// }

.datatable-body-cell
{
   text-align: center;
}

// .datatable-header {
//   background-color: #f7f7f7;
// }

// .datatable-row-odd {
//   background-color: #f5f7f9;
// }

.empty-row {
  text-align: center;
  font-weight: bold;
}

ion-modal {
  --width: 45%;
  --height: 65%;
}

.modebar-container {
  top: 200px !important; // para ficar embaixo
}

.obligatory {
  color: #fa3434 !important;
  margin-left: 0 !important;
}

.custom-popover {
  --width: 20%; /* Define a largura do popover */
  --height: 40%; /* Define a altura do popover */
}